import Box from '@mui/material/Box';
import { Button, Divider, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GeneratedWithAI from './tool/components/generatedWithAI';
import logo from './images/imagetocaptionai.svg';
import { MusicNote } from '@mui/icons-material';
import { RegisterButton } from './tool/components/registerArea';

export function Footer() {

  return (
    <footer>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 8 }}>

      </Box>
      <Box sx={{ flexGrow: 1, pb: 4 }}>
        <Box>
          <img style={{ width: "200px" }} src={logo} alt="imagetocaption logo"></img>
          <Typography variant="body2" component="p">
            ©2024. imagetocaption.ai is a product by imagetocaption GmbH
          </Typography>
        </Box>
        <Box pt={1} pb={3}>
          <Divider />
        </Box>
        <RegisterButton />
        <Box pt={1} pb={3}>
          <Divider />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Typography variant="h6" component="h5">Generated with AI</Typography>
            <GeneratedWithAI />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="h6" component="h5">Pages</Typography>
            <Stack direction="column">
              <Stack direction="row" spacing={2} justifyContent={"left"}>
                <a href="https://www.instagram.com/imagetocaptionai/" target='_blank' rel="noreferrer" title="Instagram">
                  <IconButton
                    aria-label="InstagramButton"
                    color='primary'
                  >
                    <InstagramIcon />
                  </IconButton>
                </a>
                <a href="https://www.tiktok.com/@imagetocaptionai" target='_blank' rel="noreferrer" title="TikTok">
                  <IconButton
                    aria-label="TikTok Button"
                    color='primary'
                    title="TikTok"
                  >
                    <MusicNote />
                  </IconButton>
                </a>
                <a href="https://www.facebook.com/people/Imagetocaptionai/100094291297207/" target='_blank' rel="noreferrer" title="Facebook">
                  <IconButton
                    aria-label="FacebookButton"
                    color='primary'
                  >
                    <FacebookIcon />
                  </IconButton>
                </a>
                <a href="https://twitter.com/imagetocaption" target='_blank' rel="noreferrer" title="X">
                  <IconButton
                    aria-label="TwitterButton"
                    color='primary'
                  >
                    <TwitterIcon />
                  </IconButton>
                </a>
                <a href="https://www.linkedin.com/company/imagetocaption-ai" target='_blank' rel="noreferrer" title="LinkedIn">
                  <IconButton
                    aria-label="LinkedInButton"
                    color='primary'
                  >
                    <LinkedInIcon />
                  </IconButton>
                </a>
              </Stack>
              <Stack direction="row" spacing={3} justifyContent={"left"}>
                <a aria-label="BlogButton" href="/blog"><Button sx={{ textAlign: "left" }}>Blog</Button></a>
                <a aria-label="PrivacyPolicyButton" href="/privacy-policy"><Button sx={{ textAlign: "left" }}>Privacy Policy</Button></a>
                <a aria-label="ImprintButton" href="/legal-info"><Button sx={{ textAlign: "left" }}>Legal Info</Button></a>
                <a aria-label="TermsAndConditionsButton" href="/terms-and-conditions"><Button sx={{ textAlign: "left" }}>Terms and Conditions</Button></a>
              </Stack>
              <a aria-label="InvestorButton" href="/investors"><Button sx={{ textAlign: "left" }}>Investors</Button></a>

            </Stack>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h6" component="h5">Other cool sites</Typography>

            <a href='https://pet-photoai.com' target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}>
              <Typography variant="body1" align="left" sx={{
                textDecoration: "none",
                fontWeight: "bold",
                color: "primary.main",
              }} >Pet Photo AI</Typography>
            </a>
          </Grid>
        </Grid>
      </Box>
    </footer>
  );
}